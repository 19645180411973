body {
  margin: 0;
  font-family:
    "Poppins",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}
.text-header {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 140%;
  text-align: left;
}
.text-header-mobile {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  text-align: left;
}
.text-body {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #64748b;
  text-align: left;
}

.body-width {
  max-width: 1280px;
  width: 100%;
  padding-right: 64px;
  padding-left: 64px;
  padding-top: 64px;
  margin: auto;
  text-align: left;
}

.color {
  color: #144cc3;
}
input {
  height: 12px !important;
}
.otp-input {
  height: 40px !important;
}

.paystack-hidden {
  display: none;
}

.menu-mobile {
  width: 96% !important;
  left: 0px !important;
  height: 100vh !important;
  max-height: 100vh !important;
}
.menu-mobile .MuiBackdrop-root {
  width: 100%;
  background-color: rgba(128, 128, 128, 0.7) !important;
}
.menu-mobile .MuiPaper-root {
  margin-top: 0px !important;
  height: 100vh !important;
  max-height: 100vh !important;
  top: 0px !important;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 70%;
  left: 0 !important;
}

.large-screen-menu {
}
.large-screen-menu .MuiPaper-root {
  right: 37px !important;
  left: none !important;
}


.inter-medium-dark {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  color: #616161;
  font-size: 12px;
  color: #333;
}

.order-table {
  width: 100%;
  /* border-collapse: separate; */
  padding: 10px;
  border-spacing: 0 10px;   
  font-family: 'Poppins', sans-serif;

}

.order-table th,
.order-table td {
  padding:12px;
  text-align: left;
}


.order-table th {
  background-color: #f5f5f5;
  font-weight: 600;
  color: #555;
}

.order-table tbody tr { 
   border: 12px solid pink ;
   background-color: #fff;


  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); */
}

.order-table tr.even-row {
  border-radius: 12px !important;
   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); 

}

.order-table tr.odd-row {
  background-color: #fff;

}


.order-table tbody tr:hover {
  background-color: #f0f0f0;
  cursor: pointer;
  border-color: beige;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); */

}
tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

tr td:last-child {
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
}

.order-table .empty-message {
  text-align: center;
  padding: 20px;
  font-size: 16px;
}
.order-table thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.gas-price-marquee {
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}

.gas-price-marquee div {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.input-type .MuiInputBase-input {
  height: 36px !important;
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
}

.card-witch {
  width: 80px !important;
}
.card-witch .MuiSwitch-thumb {
  text-align: right !important;
}
.card-witch .Mui-checked {
  width: 80px !important;
}

.MuiBox-root header {
  border-bottom-width: 0px;
}

.body-container{
  background-color: #FFF5F0;
  border-radius: 14px;
  padding: 20px;
}

.link-text {
	text-decoration: none;
	transition: color 0.3s ease;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-family: "Poppins", sans-serif;

  color: white;
  font-size: 16px;
  line-height: 24px;
}

.link-text:hover {
	color: #007bff; 
}
