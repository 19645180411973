.footer {
  /* background-color: #f5f5f5; */
  background-color: black;
  padding-bottom: 15px;
  text-align: center;
  color: #555;
  margin-top: 15px;
  width: 100%;
  text-align: center;
}
